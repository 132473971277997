<template>
    <div class="users-page">
        <div class="pb-6 diagonal pb-8 pt-0 pt-md-5" >
            <div class="row">
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col"><h3 class="mb-0">Partneri</h3></div>
                                <div class="col text-right">

                                        <template>
                                            <a-button type="primary" @click="openpartnermodal">Nový partner</a-button>
                                        </template>

                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :rowKey="record => record.id"
                                    :dataSource="data"
                                    :loading="loading"
                                    class="table-flush">
                                <template slot="user_role" slot-scope="user">
 <a class="text-left text-lowercase" :href="'role/'+ role" :key="index" v-for="(role,index) in JSON.parse(user.type_id)"> {{roleops[role]}}<span v-if="index+1 < JSON.parse(user.type_id).length">, </span></a>
                                </template>
                                <template slot="name" slot-scope="user">
                                    <a class="btn text-default btn-icon btn-secondary btn-sm" :href="'/user/' + user.id">
                                        <span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</a>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(user.id)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tohoto používateľa?</h5>
                                            Používateľ bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                    </a-popconfirm>
                                </template>

                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <a-modal :title="'Pridajte partnera'" centered  v-model="partnermodal" :width="900"   v-if="partnermodal" cancelText="Zrušiť" @cancel="partnermodal = false" okText="Vytvoriť partnera" @ok="addPartner">
        <a-row>
           <a-col :span="12">
               <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Názov" class="mb-0">
               <a-input  class=" " v-model="newPartner.title"    />
            </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Ulica" class="mb-0">
               <a-input  class=" " v-model="newPartner.street"    />
            </a-form-item>
            </a-col>
            </a-row>
              <a-row>
           <a-col :span="12">
               <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Obec" class="mb-0">
               <a-input  class=" " v-model="newPartner.city"    />
            </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="PSČ" class="mb-0">
               <a-input  class=" " v-model="newPartner.postalcode"    />
            </a-form-item>
            </a-col>
            </a-row>
         <a-row>
           <a-col :span="12">
               <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Názov" class="mb-0">
               <a-input  class=" " v-model="newPartner.title"    />
            </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Ulica" class="mb-0">
               <a-input  class=" " v-model="newPartner.street"    />
            </a-form-item>
            </a-col>
            </a-row>  <a-row>
           <a-col :span="12">
               <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="IČO" class="mb-0">
               <a-input  class=" " v-model="newPartner.ico"    />
            </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="DIČ" class="mb-0">
               <a-input  class=" " v-model="newPartner.dic"    />
            </a-form-item>
            </a-col>
            </a-row>
  <a-row>
           <a-col :span="12">
               <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="IČ DPH" class="mb-0">
               <a-input  class=" " v-model="newPartner.icpdh"    />
            </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Štát" class="mb-0">
               <a-input  class=" " v-model="newPartner.state"    />
            </a-form-item>
            </a-col>
            </a-row>
  <a-row>
           <a-col :span="12">
               <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Právna forma" class="mb-0">
               <a-input  class=" " v-model="newPartner.lawform"    />
            </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item :label-col="{span: 9}" :wrapper-col="formItemLayout.wrapperCol" label="Splatnosť faktúr" class="mb-0">
               <a-input-number  class=" " v-model="newPartner.duedate"    />
            </a-form-item>
            </a-col>
            </a-row>
        </a-modal>
    </div>
</template>
<script>

    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"

    const columns = [
        {
            title: 'Name',
            dataIndex: 'Nazov',
            sorter: true,
            width: '20%',
        },
        {
            title: 'IČO',
            dataIndex: 'ICO',
            width: '20%',
        },
        {
            title: 'DIČ',
            dataIndex: 'DIC',
            width: '20%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];


const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const formTwoLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};


    export default {
        name: "partners",
        components: {
            "a-popconfirm": Popconfirm
        },
        data() {
            return {
                data: [],
                roleops: [],
                formItemLayout,
                partnermodal: false,
                newPartner: {},
                loading: false,
                   lawform: [
                    {"value": "Fyzická osoba","label": "Fyzická osoba"},
                    {"value": "Právnicka osoba","label": "Právnicka osoba"},
                    ],
                colorsto: JSON.parse(localStorage.colors),
                columns,
            };
        },

        mounted() {
            dataService.axiosFetch("roles").then(results => {

                results.forEach(obj => {
                    var iid = obj.id;
                    var vval = obj.title;
                    this.roleops[iid] = vval;


                });
            });
            this.$nextTick(() => {
                this.fetch();

            })

        },
        methods: {
            openpartnermodal()
            {
this.partnermodal = true;

            },
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {

                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            addPartner()
            {
                dataService.axiosPost(this.$options.name, this.newPartner).then(results => {
                if(results.data) { this.fetch(); }
                });
            },
            cancel() {
            },
            fetch()
            {this.loading = true; dataService.axiosFetch(this.$options.name).then(results => { this.data =  results; this.loading = false;  });}

        },
    };
</script>

<style scoped>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
